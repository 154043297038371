import * as THREE from 'three'
import { WEBGL } from 'three/examples/jsm/WebGL'
import { DragControls } from 'three/examples/jsm/controls/DragControls'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { World, System, Component, TagComponent, Types, Not } from 'ecsy'
import './vox'

if (!WEBGL.isWebGLAvailable()) {
  document.body.appendChild(WEBGL.getWebGLErrorMessage())
  throw new Error('WebGL not supported')
}

function mix(a, b, bw) {
  return a * bw + b * (1 - bw)
}

function closeEnough(a, b) {
  return Math.abs(a - b) < 0.01
}

// Ecsy

class Position extends Component {}
Position.schema = {
  x: { type: Types.Number },
  y: { type: Types.Number },
}

class Scale extends Component {}
Scale.schema = {
  scale: { type: Types.Number },
}

class Rotation extends Component {}
Rotation.schema = {
  rotation: { type: Types.Number },
}

class Visual extends Component {}
Visual.schema = {
  mesh: { type: Types.Ref },
}

class NewData extends Component {}
NewData.schema = {
  data: { type: Types.Ref },
}

class Moving extends TagComponent {}

class Rotating extends TagComponent {}

class Rescaling extends TagComponent {}

class Dispose extends TagComponent {}

class ApplyNewData extends System {
  execute(delta, time) {
    this.queries.objects.results.forEach((entity) => {
      var data = entity.getComponent(NewData).data

      if (entity.hasComponent(Position)) {
        var position = entity.getMutableComponent(Position)
        position.x = data.pos_x
        position.y = data.pos_y

        if (entity.hasComponent(Visual)) {
          entity.addComponent(Moving)
        }
      }

      entity.removeComponent(NewData)
    })
  }
}
ApplyNewData.queries = {
  objects: { components: [NewData] },
}

class MoveVisual extends System {
  execute(delta, time) {
    this.queries.objects.results.forEach((entity) => {
      var position = entity.getComponent(Position)
      var visual = entity.getComponent(Visual)

      visual.mesh.position.x = mix(
        position.x,
        visual.mesh.position.x,
        delta * 0.008,
      )
      visual.mesh.position.z = mix(
        position.y,
        visual.mesh.position.z,
        delta * 0.008,
      )

      if (
        closeEnough(visual.mesh.position.x, position.x) &&
        closeEnough(visual.mesh.position.z, position.y)
      ) {
        entity.removeComponent(Moving)
      }
    })
  }
}
MoveVisual.queries = {
  objects: { components: [Moving, Visual, Position] },
}

class RescaleVisual extends System {
  execute(delta, time) {
    this.queries.objects.results.forEach((entity) => {
      var scale = entity.getComponent(Scale).scale
      var mesh = entity.getComponent(Visual).mesh

      mesh.scale.x = mix(scale, mesh.scale.x, delta * 0.008)
      mesh.scale.y = mix(scale, mesh.scale.y, delta * 0.008)
      mesh.scale.z = mix(scale, mesh.scale.z, delta * 0.008)

      if (
        closeEnough(mesh.scale.x, scale) &&
        closeEnough(mesh.scale.y, scale) &&
        closeEnough(mesh.scale.z, scale)
      ) {
        entity.removeComponent(Rescaling)
      }
    })
  }
}
RescaleVisual.queries = {
  objects: { components: [Rescaling, Visual, Scale] },
}

class RotateVisual extends System {
  execute(delta, time) {
    this.queries.objects.results.forEach((entity) => {
      var rotation = entity.getComponent(Rotation).rotation
      var mesh = entity.getComponent(Visual).mesh

      mesh.rotation.y = mix(rotation, mesh.rotation.y, delta * 0.008)

      if (closeEnough(mesh.rotation.y, rotation)) {
        entity.removeComponent(Rotating)
      }
    })
  }
}
RotateVisual.queries = {
  objects: { components: [Rotating, Visual, Rotation] },
}

class DisposeVisual extends System {
  execute(delta, time) {
    this.queries.objects.results.forEach((entity) => {
      var mesh = entity.getComponent(Visual).mesh
      mesh.parent.remove(mesh)

      console.log('mesh removed')
      entity.removeComponent(Dispose)
      entity.remove()
    })
  }
}
DisposeVisual.queries = {
  objects: { components: [Dispose, Visual, Not(Rescaling), Not(Moving)] },
}

var world = new World()
world
  .registerComponent(Position)
  .registerComponent(Scale)
  .registerComponent(Rotation)
  .registerComponent(Visual)
  .registerComponent(NewData)
  .registerComponent(Moving)
  .registerComponent(Rescaling)
  .registerComponent(Rotating)
  .registerComponent(Dispose)
  .registerSystem(ApplyNewData)
  .registerSystem(MoveVisual)
  .registerSystem(RescaleVisual)
  .registerSystem(RotateVisual)
  .registerSystem(DisposeVisual)

// Three

var scene = new THREE.Scene()
scene.background = new THREE.Color('rgb(80, 80, 80)')
var camera = new THREE.OrthographicCamera()
camera.position.set(-1000, 1000, 1000)
camera.far = 3000
camera.rotation.order = 'YXZ'
camera.rotation.y = -Math.PI / 4
camera.rotation.x = Math.atan(-1 / Math.sqrt(2))
var renderer = new THREE.WebGLRenderer({ antialias: true })
document.body.appendChild(renderer.domElement)

var socket

// fetch(
//   'https://raw.githubusercontent.com/async-rs/async-tls/master/tests/end.chain'
// ).then(r => init_socket(r.text()))

init_socket()

function init_socket() {
  // Websocket
  socket = new WebSocket('wss://yolk.ostwilkens.se:9016/')

  socket.onopen = function (e) {
    console.log('socket opened')

    var cmd = {
      type: 'UpdateView',
      view: { pos: { x: -10, y: -10 }, size: { x: 20, y: 20 } },
    }
    var json = JSON.stringify(cmd)
    socket.send(json)
  }

  socket.onmessage = function (event) {
    let msgs = JSON.parse(event.data)

    for (let i = 0; i < msgs.length; i++) {
      let msg = msgs[i]

      let is_array = msg.constructor === Array
      if (is_array) {
        let unit_id = msg[0]
        let unit = msg[1]
        let pos = unit.pos
        let facing = unit.facing

        // add unit if not exists
        let ent = world.entityManager.getEntityByName(unit_id)
        if (ent) {
          // ent.addComponent(NewData, { data: { pos_x: pos.x, pos_y: pos.y } });
          console.error('received unit already exists')
        } else {
          var parser = new vox.Parser()
          let uint8array = Uint8Array.from(unit.model)

          ent = world
            .createEntity(unit_id)
            .addComponent(Position, { x: pos.x, y: pos.y })
            .addComponent(Scale, { scale: 0.2 })
            .addComponent(Rescaling)
            .addComponent(Rotation, { rotation: 0 })

          parser.parseUint8Array(uint8array, (error, voxelData) => {
            if (error) {
              console.error(error)
            }
            var builder = new vox.MeshBuilder(voxelData)
            var mesh = builder.createMesh()
            mesh.position.y += 0.5
            mesh.position.x = pos.x
            mesh.position.z = pos.y
            mesh.scale.set(0, 0, 0)
            scene.add(mesh)

            ent.addComponent(Visual, { mesh: mesh })
          })
        }

        continue
      }

      switch (msg.type) {
        case 'UnitMoved': {
          let unit_id = msg.id
          let pos = msg.new_pos

          // add unit if not exists
          let ent = world.entityManager.getEntityByName(unit_id)
          if (ent) {
            ent.addComponent(NewData, { data: { pos_x: pos.x, pos_y: pos.y } })
          } else {
            // console.log("New unit: ", unit_id);

            console.error('new position received for unknown unit')
            // var geometry = new THREE.BoxGeometry(1, 1, 1);
            // var material = new THREE.MeshStandardMaterial({ color: 0xf44b2a });
            // var mesh = new THREE.Mesh(geometry, material);
            // mesh.position.y += 0.5;
            // mesh.position.x = pos.x;
            // mesh.position.z = pos.y;
            // mesh.scale.set(0, 0, 0);
            // scene.add(mesh);

            // world.createEntity(unit_id)
            //     .addComponent(Position, { x: pos.x, y: pos.y })
            //     .addComponent(Visual, { mesh: mesh })
            //     .addComponent(Scale, { scale: 1 })
            //     .addComponent(Rescaling)
            //     ;
          }

          break
        }
        case 'UnitTurned': {
          let unit_id = msg.id
          let dir = msg.new_dir

          let rotation = -1
          switch (dir) {
            case 'Up':
              rotation = -Math.PI * 0
              break
            case 'Right':
              rotation = -Math.PI * 0.5
              break
            case 'Down':
              rotation = -Math.PI * 1
              break
            case 'Left':
              rotation = -Math.PI * 1.5
              break
          }

          let ent = world.entityManager.getEntityByName(unit_id)
          if (ent) {
            if (ent.hasComponent(Rotation)) {
              var rotationComponent = ent.getMutableComponent(Rotation)
              rotationComponent.rotation = rotation

              if (ent.hasComponent(Visual)) {
                ent.addComponent(Rotating)
              }
            }
          } else {
            console.error('new facing received for unknown unit')
          }

          // var data = entity.getComponent(NewData).data;
          // if (entity.hasComponent(Position)) {
          //     var position = entity.getMutableComponent(Position);
          //     position.x = data.pos_x;
          //     position.y = data.pos_y;

          //     if (entity.hasComponent(Visual)) {
          //         entity.addComponent(Moving);
          //     }
          // }
          // entity.removeComponent(NewData);

          break
        }
        default:
          console.error(`unknown message type ${msg.type}`)
      }
    }

    // pub enum PatchEvent {
    //     UnitMoved { id: u64, new_pos: Vec2i32 },
    //     UnitTurned { id: u64, new_dir: Direction },
    // }
  }

  socket.onclose = function (event) {
    console.log(`socket closed, code=${event.code} reason=${event.reason}`)
  }

  socket.onerror = function (error) {
    console.error(`socket error: ${error.message}`)
  }
}

// Firebase

firebase.initializeApp({
  apiKey: 'AIzaSyAqBdvKeVxEvPY4WssXjcI5GcEDj2LO8rc',
  authDomain: 'thick-whip.firebaseapp.com',
  databaseURL: 'https://thick-whip.firebaseio.com',
  projectId: 'thick-whip',
  storageBucket: 'thick-whip.appspot.com',
  messagingSenderId: '851111468035',
  appId: '1:851111468035:web:f3c26c15a806a2740d0236',
})

var db = firebase.firestore()

db.collection('units').onSnapshot(function (snapshot) {
  snapshot.docChanges().forEach(function (change) {
    if (change.type === 'added') {
      var data = change.doc.data()
      console.log('New unit: ', data)

      var geometry = new THREE.BoxGeometry(1, 1, 1)
      var material = new THREE.MeshStandardMaterial({ color: 0xf44b2a })
      var mesh = new THREE.Mesh(geometry, material)
      mesh.position.y += 0.5
      mesh.position.x = data.pos_x
      mesh.position.z = data.pos_y
      mesh.scale.set(0, 0, 0)
      scene.add(mesh)

      world
        .createEntity(change.doc.id)
        .addComponent(Position, { x: data.pos_x, y: data.pos_y })
        .addComponent(Visual, { mesh: mesh })
        .addComponent(Scale, { scale: 1 })
        .addComponent(Rescaling)
    }
    if (change.type === 'modified') {
      var data = change.doc.data()
      console.log('Modified unit: ', data)

      world.entityManager
        .getEntityByName(change.doc.id)
        .addComponent(NewData, { data: data })
    }
    if (change.type === 'removed') {
      console.log('Removed unit: ', change.doc.data())

      var entity = world.entityManager.getEntityByName(change.doc.id)
      entity.getMutableComponent(Scale).scale = 0
      entity.addComponent(Rescaling).addComponent(Dispose)
    }
  })
})

window.onresize = () => {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  )
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  )

  renderer.setSize(vw, vh)

  camera.left = vw / -100
  camera.right = vw / 100
  camera.top = vh / 100
  camera.bottom = vh / -100

  camera.updateProjectionMatrix()
}

window.onresize.call()

// window.onmouseup = () => {
//     console.log("test");
//     var cmd = { type: "UpdateView", view: { pos: { x: -10, y: -10 }, size: { x: 20, y: 20 } } };
//     var json = JSON.stringify(cmd);
//     socket.send(json);
// }

function onDocumentPointerUp(event) {
  event.preventDefault()

  let center_x = camera.position.x + 1000
  let center_y = camera.position.z - 1000

  let radius = Math.max(camera.right, camera.top)

  let left = Math.floor(center_x - radius / camera.zoom / 0.8)
  let top = Math.floor(center_y - radius / camera.zoom / 0.8)

  let size_x = Math.ceil((radius * 2) / camera.zoom / 0.8)
  let size_y = Math.ceil((radius * 2) / camera.zoom / 0.8)

  // let angle = Math.PI / 4
  // let left = Math.floor(Math.cos(angle) * left - Math.sin(angle) * top);
  // let top = Math.floor(Math.sin(angle) * left + Math.cos(angle) * top);

  // let x = Math.floor(camera.position.x + camera.right);
  // console.log(x);

  // let x = Math.floor(camera.position.x);
  // let y = Math.floor(camera.position.y);
  // let z = Math.floor(camera.position.z);
  // console.log(left, right, top, bottom);

  // console.log(camera.right);

  var cmd = {
    type: 'UpdateView',
    view: { pos: { x: left, y: top }, size: { x: size_x, y: size_y } },
  }
  var json = JSON.stringify(cmd)
  socket.send(json)
}

document.addEventListener('pointerup', onDocumentPointerUp, false)

{
  var geometry = new THREE.PlaneGeometry(8, 8)
  var material = new THREE.MeshStandardMaterial({ color: 0x7bc8a4 })
  var mesh = new THREE.Mesh(geometry, material)
  mesh.rotation.x = -Math.PI / 2
  scene.add(mesh)
  mesh.receiveShadow = true
}

var directionalLight = new THREE.DirectionalLight(0xffffff, 0.8)
directionalLight.position.set(0.5, 1, 0.5)
scene.add(directionalLight)

scene.add(new THREE.AmbientLight(0xffffff, 0.4))

var controls = new OrbitControls(camera, renderer.domElement)
controls.enableDamping = true
controls.dampingFactor = 0.05
controls.enableRotate = false
// controls.enableZoom = false
controls.mouseButtons = { LEFT: THREE.MOUSE.PAN }
controls.touches = { ONE: THREE.TOUCH.PAN }
controls.screenSpacePanning = false

let lastTime = 0
function animate(time) {
  requestAnimationFrame(animate)
  controls.update()

  // scene.rotation.y += 0.001;

  // console.log(camera.zoom);

  const delta = time - lastTime
  lastTime = time
  world.execute(delta, time)

  renderer.render(scene, camera)
}

animate()
